/* You can add global styles to this file, and also import other style files */
:root {
  font-family: var(--oryx-typography-font-face);
  font-size: var(--oryx-typography-font-size);
  font-weight: var(--oryx-typography-font-weight);
  letter-spacing: 0.005em;
  color: var(--oryx-color-neutral-12);
  background-color: var(--oryx-color-neutral-1);

  // The ui-component styles package doesn't provide custom properties, only less based variables.
  // Hence we create a unique set of colors here.
  --white: #fff;
  --black: #000;
  --gray-lighter: #f8f8f8;
  --gray-light: #efeeee;
  --gray-darker: #71747b;
  --green-base: #17b497;
  --green-dark: #138671;
  --green-lighter: #f2f8f7;
  --base-z-index: 1;
  --navigation-z-index: 2;
  --overlay-z-index: 3;
}

::placeholder {
  color: var(--oryx-color-placeholder);
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}

fieldset,
legend {
  padding: 0;
  border: 0;
}

body {
  background-color: var(--gray-lighter);
  color: var(--black);
  font-family: Montserrat, sans-serif;
  margin: 0;
  height: 100%;
}

.page {
  padding-block-end: 100px;
  display: block;
}

html,
* {
  box-sizing: border-box;
}

oryx-headline,
oryx-heading {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0 30px;

  oryx-button {
    margin-inline-start: 10px;

    &:first-child {
      margin-inline-start: auto;
    }
  }

  .app-headline {
    &.move-down {
      .actions {
        width: calc(100% - var(--start));
      }

      .wrapper {
        display: flex;
        margin-inline-end: var(--scrollbar-width);
      }
    }
  }
}

oryx-spinner {
  --oryx-icon-size: 40px;

  padding-block-start: 20vh;
}

nz-card {
  box-shadow: 0 1px 3px rgba(18 18 18 / 15%);
}

.toast-container.toast-top-right {
  inset-block-start: 40px;

  spy-notification-wrapper.ngx-toastr {
    box-shadow: -2px 2px 20px rgba(18 18 18 / 15%);

    .ant-alert {
      min-width: 470px;
    }
  }
}

oryx-input {
  .error {
    --oryx-icon-size: 15px;

    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--oryx-color-error-300);
    font-size: 12px;

    oryx-icon {
      color: var(--oryx-color-error-300);
    }
  }
}

oryx-select {
  margin-block-end: 24px;

  select {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
}

.feature-toggle-hidden {
  display: none;
}
